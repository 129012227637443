import React, { FC } from "react";
import Layout from "../components/layout";

const CalculatorPage: FC = () => (
  <Layout pageTitle={"home"}>
    <p>Calculator</p>
  </Layout>
);

export default CalculatorPage;
